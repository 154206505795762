import { Link, navigate } from "gatsby"
import React, { useContext } from "react"
import { context } from "../../provider"

import Logo from "../assets/svg/logo.svg"
import MenuIcon from "../assets/svg/menu.svg"

import "./header.scss"
import { useWindowSize } from "react-use"
import useScrollDirection from "../hooks/useScrollDirection"
import { motion } from "framer-motion"

const Header = () => {
  const { transitionPage } = useContext(context)

  const { navState, setNavState } = useContext(context)

  const { width: windowWidth } = useWindowSize()

  const scrollDir = useScrollDirection()

  const toggleNav = () => {
    setNavState({ ...navState, isOpen: !navState.isOpen })
  }

  const handleHomeClick = e => {
    e.preventDefault()
    if (navState.isOpen) {
      navigate("/")
      setNavState({ ...navState, isOpen: false })
    } else {
      transitionPage({ to: "/", color: "white" })
    }
  }

  return (
    <motion.header
      className="header"
      animate={{
        y: windowWidth > 480 ? 0 : scrollDir === "down" ? "-100%" : "0%",
      }}
    >
      <div className="header__menu-icon-container">
        <motion.button
          className="outline-none focus:outline-none"
          onClick={() => toggleNav()}
        >
          <MenuIcon />
        </motion.button>
      </div>
      <motion.div
        className="header__logo-container"
        animate={{
          y: windowWidth <= 480 ? 0 : scrollDir === "down" ? "-200%" : "0%",
        }}
      >
        <Link to="/" onClick={e => handleHomeClick(e)}>
          <Logo />
        </Link>
      </motion.div>
    </motion.header>
  )
}

export default Header
