import React, { useContext } from "react"

import Header from "./Header"
import Navigation from "./Navigation"
import { useAnimation, motion } from "framer-motion"
import { useEffect } from "react"
import { defaultTransition } from "../utils/easings"
import { useRef } from "react"
import { navigate } from "gatsby"

// import { AnimatePresence, motion } from "framer-motion"
// import { defaultTransition as transition } from "../utils/easings"

// const Panels = () => {
//   return (
//     <div className="z-30 fixed inset-0 flex h-screen">
//       <div className="w-1/2 bg-gray-200"></div>
//       <div className="w-1/2 py-32 px-16 bg-red-200"></div>
//     </div>
//   )
// }

// const PAGE_VARIANTS = {
//   projectsEnter: {
//     y: "100vh",
//     z: 11,
//   },
//   enter: {
//     y: "0vh",
//     z: 13,
//     transition,
//   },
//   exit: {
//     y: "0vh",
//     transition,
//     z: 12,
//   },
// }
import { context } from "../../provider"

const TransitionSlide = () => {
  const { setTransitionPage } = useContext(context)

  const controls = useAnimation()
  const slideRef = useRef(null)

  const SLIDE_VARIANTS = {
    initial: {
      y: "100%",
    },
    cover: {
      y: "0%",
      transition: defaultTransition,
    },
    exit: {
      y: "-100%",
      transition: defaultTransition,
    },
    reset: {
      y: "100%",
      transition: {
        duration: 0,
      },
    },
  }

  const pageTransition = async ({ to, color = "#fff" }) => {
    slideRef.current.style.backgroundColor = color
    await controls.start("initial")
    await controls.start("cover")
    navigate(to)
    await controls.start("exit")
    return await controls.start("reset")
  }

  useEffect(() => {
    setTransitionPage(() => pageTransition)
  }, [])

  return (
    <motion.div
      ref={slideRef}
      animate={controls}
      variants={SLIDE_VARIANTS}
      initial="initial"
      className="h-screen w-screen fixed inset-0 z-50"
    ></motion.div>
  )
}
const Layout = ({ children, location }) => {
  return (
    <>
      <Header />
      <Navigation location={location} />
      <TransitionSlide />
      {/* <AnimatePresence initial={false}>
        <motion.main
          key={location.pathname}
          variants={PAGE_VARIANTS}
          initial={
            location.pathname == "/projects/" ? "projectsEnter" : "enter"
          }
          animate={"enter"}
          exit={"exit"}
        >
          {children}
        </motion.main>
      </AnimatePresence> */}

      <main>{children}</main>
    </>
  )
}

export default Layout
