// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dev-js": () => import("./../../../src/pages/dev.js" /* webpackChunkName: "component---src-pages-dev-js" */),
  "component---src-pages-digital-js": () => import("./../../../src/pages/digital.js" /* webpackChunkName: "component---src-pages-digital-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notebook-js": () => import("./../../../src/pages/notebook.js" /* webpackChunkName: "component---src-pages-notebook-js" */),
  "component---src-pages-projects-au-natural-js": () => import("./../../../src/pages/projects/au-natural.js" /* webpackChunkName: "component---src-pages-projects-au-natural-js" */),
  "component---src-pages-projects-cha-cha-js": () => import("./../../../src/pages/projects/cha-cha.js" /* webpackChunkName: "component---src-pages-projects-cha-cha-js" */),
  "component---src-pages-projects-double-o-js": () => import("./../../../src/pages/projects/double-o.js" /* webpackChunkName: "component---src-pages-projects-double-o-js" */),
  "component---src-pages-projects-ecan-js": () => import("./../../../src/pages/projects/ecan.js" /* webpackChunkName: "component---src-pages-projects-ecan-js" */),
  "component---src-pages-projects-ezimac-js": () => import("./../../../src/pages/projects/ezimac.js" /* webpackChunkName: "component---src-pages-projects-ezimac-js" */),
  "component---src-pages-projects-first-law-js": () => import("./../../../src/pages/projects/first-law.js" /* webpackChunkName: "component---src-pages-projects-first-law-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-ko-and-co-js": () => import("./../../../src/pages/projects/ko-and-co.js" /* webpackChunkName: "component---src-pages-projects-ko-and-co-js" */),
  "component---src-pages-projects-orbica-js": () => import("./../../../src/pages/projects/orbica.js" /* webpackChunkName: "component---src-pages-projects-orbica-js" */),
  "component---src-pages-projects-red-and-black-js": () => import("./../../../src/pages/projects/red-and-black.js" /* webpackChunkName: "component---src-pages-projects-red-and-black-js" */),
  "component---src-pages-projects-southern-creations-js": () => import("./../../../src/pages/projects/southern-creations.js" /* webpackChunkName: "component---src-pages-projects-southern-creations-js" */)
}

