import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { useLocation } from "@reach/router"

import { context } from "../../provider"
import "./navigation.scss"
import { defaultTransition as transition } from "../utils/easings"
import { useLockBodyScroll, useWindowSize } from "react-use"

const menuItems = [
  { title: "Projects", path: "/#projects" },
  { title: "Digital", path: "/digital/" },
  { title: "Notebook", path: "/notebook/" },
  { title: "About us", path: "/about-us/" },
  { title: "Contact", path: "/contact/" },
]

const contactItems = [
  { title: "5/245 St Asaph Street" },
  { title: "SALT District" },
  { title: "Christchurch 8011" },
  { title: "03 925 8199", href: "tel:+6433841810" },
  {
    title: "info@fridaycreative.nz",
    href:
      "mailto:info@fridaycreative.nz?subject=Enquiry from Friday Creative website",
  },
  { title: "Instagram", href: "https://www.instagram.com/friday_creative/" },

  {
    title: "Facebook",
    href: "https://www.facebook.com/FridayCreativeBrandDevelopers/",
  },
  {
    title: "LinkedIn",
    href: "https://www.linkedin.com/company/friday-creative-nz/",
  },
]

const Navigation = () => {
  const {
    navState,
    navState: { isOpen },
    setNavState,
  } = useContext(context)

  const location = useLocation()

  useLockBodyScroll(isOpen)

  const variants = {
    open: {
      y: "0%",
    },
    closed: {
      y: "100%",
    },
  }

  // useEffect(() => {
  //   window.onerror = (errorMsg, url, lineNumber) => {
  //     alert("Error in " + url + " at " + lineNumber + ":\n" + errorMsg)
  //   }
  // }, [])

  const { height } = useWindowSize()

  useEffect(() => {
    setNavState({ ...navState, isOpen: false })
  }, [location.href]) // eslint-disable-line

  useEffect(() => {
    if (isOpen) {
      document.querySelector("html").style.overflow = "hidden"
      document.querySelector("body").style.overflow = "hidden"
    } else {
      document.querySelector("html").style.overflow = ""
      document.querySelector("body").style.overflow = ""
    }
  }, [isOpen]) // eslint-disable-line

  return (
    <motion.div
      className={`navigation`}
      variants={variants}
      style={{ height: height ? height * 1.2 : "" }}
      animate={isOpen ? "open" : "closed"}
      transition={transition}
      initial={"closed"}
    >
      <div className="panel--text navigation__left-panel ">
        <div className="flex flex-col items-start">
          {menuItems.map((item, index) => (
            <Link
              key={item.path}
              to={item.path}
              className="navigation__link"
              onClick={() => setNavState({ ...navState, isOpen: false })}
              activeClassName="active"
            >
              <h2>{item.title}</h2>
            </Link>
          ))}
        </div>
      </div>
      <div className="navigation__right-panel panel--text">
        {contactItems.map(item => (
          <div key={item.title} className={item.href ? "navigation__link" : ""}>
            {item.href && (
              <a href={item.href} target="_blank">
                <h3>{item.title}</h3>
              </a>
            )}
            {!item.href && <h3>{item.title}</h3>}
          </div>
        ))}
      </div>
      <span className="copyright-line absolute bottom-0 mb-12">
        © Friday Creative 2020
      </span>
    </motion.div>
  )
}

export default Navigation
