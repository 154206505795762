import React, { useState, useEffect } from "react"

export const context = React.createContext()

const Provider = ({ children }) => {
  const [navState, setNavState] = useState({
    isOpen: false,
  })
  const [transitionPage, setTransitionPage] = useState(() => {})

  useEffect(() => {
    console.log("navState:", navState)
  }, [navState])

  const providerContext = {
    navState,
    setNavState,
    transitionPage,
    setTransitionPage,
  }

  return <context.Provider value={providerContext}>{children}</context.Provider>
}

export default ({ element }) => <Provider>{element}</Provider>
