/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import scrollTo from "./src/animations/scrollTo.js"

import "./src/styles/base/mixins.scss"

import "./src/global.scss"

import Provider from "./provider"

export const wrapRootElement = Provider

window.scrollToFn = scrollTo
